* {
  --white: #ffffff !important;
  --primary-color: #2e2861 !important;
  --secondary-color: #4f4e80 !important;
  --thirty-color: #f5f5f5 !important;
  --fourth-color: #d8d8d8 !important;
  --generic-table-amount-color: #b6b5b5 !important;
  font-family: "Nunito", sans-serif;
  box-sizing: border-box;
}

html,
body,
#root,
#root > .Dashboard-container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.google-map {
  border-radius: 12px;
}

p,
span,
div {
  font-size: 14px;
}

body {
  background-color: #f5f3f8;
}

.transparent {
  background-color: transparent !important;
  color: var(--black) !important;
}

.break-word {
  word-break: break-word;
}

.rdw-editor-toolbar,
.rdw-editor-main {
  border-radius: 12px !important;
  background-color: #ffffff;
}

.tui-image-editor-container .tui-image-editor-controls-logo > img,
.tui-image-editor-container .tui-image-editor-header-logo > img {
  display: none !important;
}

.tui-image-editor-container .tui-image-editor-header-buttons,
.tui-image-editor-container .tui-image-editor-controls-buttons {
  display: none !important;
}

.shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.ft-14 {
  font-size: 14px !important;
}

.ft-12 {
  font-size: 12px !important;
}

.w-50 {
  width: 50%;
}

.pr-40 {
  padding-right: 40px !important;
}

.pr-80 {
  padding-right: 80px !important;
}

.d-inline-flex {
  display: inline-flex;
}

.ignore-flex {
  flex-grow: 0;
  flex-shrink: 0;
}

.p-40 {
  padding: 40px !important;
}

.bg-blue-extra-light {
  background-color: #eff5f9;
}

.bg-fourth-color {
  background-color: var(--fourth-color) !important;
}

.bg-generic-table-amount {
  background-color: var(--generic-table-amount-color) !important;
}

.text-bold {
  font-weight: bold;
}

.w-50 {
  width: 50%;
}

.rdw-editor-main {
  padding: 10px;
  border: 1px solid #f1f1f1;
  min-height: 200px;
}

.rotate {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

textarea {
  resize: none;
}

.DraftEditor-root {
  font-size: 14px;
}
.public-DraftStyleDefault-block {
  margin: 0px !important;
}

.clickable {
  cursor: pointer;
  transition: 0.3s;
}
.clickable:hover {
  opacity: 0.8;
}
.clickable:active {
  opacity: 0.6;
}

.col_full_height {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}
.space-beetwen {
  justify-content: space-between;
}

.justify-end {
  justify-content: end;
}
.gap-5 {
  gap: 5;
}

.active {
  background-color: #a153ee;
  color: #ffffff !important;
}
.rotate {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.ql-snow {
  border-radius: 8px !important;
}

.ql-toolbar {
  margin-bottom: 8px;
  background-color: var(--secondary-color);
  border-color: var(--secondary-color) !important;
}

.ql-container {
  border-top: 1px solid #ccc !important;
}

.ql-editor {
  min-height: 120px;
}

.overflow-hidden {
  overflow: hidden;
}

.w-300 {
  width: 300px;
}

.o-8 {
  opacity: 0.6;
}

.min-width-full {
  min-width: 100%;
}

.relative {
  position: relative;
}

.disable {
  color: var(--light-grey);
}

.disable:hover {
  cursor: not-allowed;
}

/* For Filter SearchView Button*/
@media screen and (max-width: 600px) {
  .search-view-filter-button-flex-direction {
    flex-direction: column;
  }
}

/* Managing*/
@media screen and (max-width: 600px) {
  .managing-flex-direction {
    flex-direction: column;
  }

  .managing-table-width {
    width: 100% !important;
  }
}
